import { HeadersProps } from '../types';

export const getBackgroundColor = (
  agregadoresPorCampo: Map<number, HeadersProps>,
  codCampo: number,
): string => {
  const agregador = agregadoresPorCampo.get(codCampo);

  if (agregador) return '#e5e5e5';
  return 'transparent';
};
