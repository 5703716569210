/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Agregadores } from './Agregadores';
import { useDataTable } from './context/DataTableContext';
import {
  Table,
  TableContainer,
  TableHeader,
  Tbody,
  Td,
  Tr,
  TrAgregadores,
  TrHeader,
} from './styles';
import { TableHeaderCell } from './TableHeaderCell';
import { AgregadorTipo, DataTableProps, HeadersProps } from './types';
import { agregadorLabel } from './utils/agregadorLabel';
import { formatValue } from './utils/formatValue';
import { useSearch } from '../Search/SearchContext';
import { getColorLine, getColorOdd, getColorText } from '~/utils/getGridColors';
import { useTableViewManager } from '~/context/tableViewManager';
import { getBackgroundColor } from './utils/getBackgroundColor';

export const DataTableContent: React.FC<DataTableProps> = ({
  columns,
  rows,
  loading,
  components,
  totals,
  onRowClick,
  handleSort,
  sortCampos,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [heightFromBody, setHeightFromBody] = useState(0);
  const [widthTr, setWidthTr] = useState(0);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  const trRef = useRef<HTMLTableRowElement | null>(null);
  const tableHeaderRef = useRef<HTMLTableSectionElement | null>(null);

  const { dynamicWidth } = useDataTable();
  const { filtersDaTela, loadingTable } = useSearch();
  const { qtdColunasFixadas } = useTableViewManager();

  const LoadingOverlayComponent = components?.LoadingOverlay ?? (() => null);
  const PaginationComponent = components?.Pagination ?? (() => null);

  const agregadores = columns.filter(
    (column) => column.tipo_agregador_tot !== AgregadorTipo.NENHUM,
  );

  const agregadoresPorCampo = new Map<number, HeadersProps>();

  agregadores.forEach((agregador) => {
    if (agregador.width < 150) agregador.width = 150;
    agregadoresPorCampo.set(agregador.codCampo, agregador);
  });

  const items = rows.length;
  const noRecordsFound = items <= 0 && !loading;
  const showAgregadores = !loading && agregadores.length > 0 && items > 0;
  const isFixedList = columns.map((col, indx) => indx < qtdColunasFixadas);

  useEffect(() => {
    if (loading && loadingTable) {
      setScrollPosition(0);
      setHeightFromBody(0);
    }
  }, [loading, loadingTable]);

  const handleScroll = useCallback(() => {
    setScrollPosition(0);

    if (tableHeaderRef.current) {
      const headerPosition =
        tableHeaderRef.current.getBoundingClientRect().top + window.scrollY;

      if (headerPosition >= heightFromBody) setScrollPosition(window.scrollY);
    }
  }, [heightFromBody]);

  const isValidHex = (hexColor: string) =>
    /^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(hexColor);

  const getColorOrDefault = useCallback((item: any): string => {
    const DEFAULT_COLOR = '#FFFFFF';
    const hexColor = String(item.color).toUpperCase();

    return isValidHex(hexColor) ? hexColor : DEFAULT_COLOR;
  }, []);

  const getColorWithFallback = useCallback(
    (color: string, fallbackColor: string): string => {
      return isValidHex(color) ? color : fallbackColor;
    },
    [],
  );

  const sumWidths = useCallback((arry: HeadersProps[]) => {
    return arry.reduce((total, column) => {
      return total + (column.width || 0);
    }, 0);
  }, []);

  useEffect(() => {
    setHeightFromBody(0);
    const tableHeader = tableHeaderRef.current;

    if (tableHeader) {
      const bodyTop = document.body.getBoundingClientRect().top;
      const tableHeaderTop = tableHeader.getBoundingClientRect().top;
      const heightFromBodyToTableHeader = tableHeaderTop - bodyTop;

      const heigth =
        heightFromBodyToTableHeader === 0 ? 321 : heightFromBodyToTableHeader;
      const heigthFix = heigth >= 500 ? 286 : heigth;

      if (heigthFix > 0) setHeightFromBody(heigthFix);
    }
  }, [window.location.href, filtersDaTela, loadingTable, sortCampos]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [heightFromBody]);

  useEffect(() => {
    const updateWidth = () => {
      if (trRef.current) {
        const browserWidth = window.innerWidth;
        const elementWidth = trRef.current.offsetWidth;

        setWidthTr(Math.min(elementWidth, browserWidth));
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div style={{ border: '0.0625rem solid #e0e0e0' }}>
      <TableContainer count={items}>
        <Table>
          <TableHeader
            ref={tableHeaderRef}
            style={{ top: `${Math.max(scrollPosition - heightFromBody, 0)}px` }}
            qtdFixed={qtdColunasFixadas}
          >
            <TrHeader>
              {columns.map((column, idx) => {
                const qtdColumns =
                  columns.length === qtdColunasFixadas ? 0 : qtdColunasFixadas;

                const isFixed = idx < qtdColumns;

                const leftPosition = columns
                  .slice(0, idx)
                  .reduce(
                    (acc, col) =>
                      acc + (dynamicWidth[col.codCampo] ?? col.width),
                    0,
                  );

                const styleFixed: React.CSSProperties = isFixed
                  ? {
                      zIndex: 2,
                      position: 'sticky',
                      left: `${leftPosition}px`,
                      backgroundColor: '#8850bf',
                    }
                  : {};

                return (
                  <TableHeaderCell
                    key={column.codCampo}
                    column={column}
                    isLast={idx === columns.length - 1}
                    handleSort={handleSort}
                    sortCampos={sortCampos}
                    isFixed={isFixed}
                    styleFixed={styleFixed}
                  />
                );
              })}
            </TrHeader>
          </TableHeader>
          {loading && <LoadingOverlayComponent />}

          <Tbody count={items}>
            {!loading && noRecordsFound && (
              <p className="noRecordsFound">Nenhum registro encontrado...</p>
            )}

            {rows.map((row, idx) => {
              const hex = getColorOrDefault(row);

              const colorLine = getColorWithFallback(
                getColorLine(hex, 0.09, idx),
                '#FFFFFF',
              );
              const colorText = getColorWithFallback(
                getColorText(hex, 0.09, idx),
                '#000000',
              );
              const colorHover = getColorWithFallback(
                getColorOdd(hex, 0.15),
                '#F5F5F5',
              );

              return (
                <Tr
                  key={row.id || idx}
                  bgColor={colorLine}
                  bgHover={colorHover}
                  ref={trRef}
                  onMouseEnter={() => setHoveredRowIndex(idx)}
                  onMouseLeave={() => setHoveredRowIndex(null)}
                >
                  {columns.map((column, index) => {
                    const isLast = index === columns.length - 1;

                    const qtdColumns =
                      columns.length === qtdColunasFixadas
                        ? 0
                        : qtdColunasFixadas;

                    const isFixed = index < qtdColumns;

                    const calculatedWidth = (() => {
                      if (isLast && columns.length < 5) {
                        const checkWidth = widthTr === 0 ? 1170 : widthTr;
                        const calc = checkWidth - sumWidths(columns);
                        return calc + column.width;
                      }
                      return dynamicWidth[column.codCampo] ?? column.width;
                    })();

                    const leftPosition = columns
                      .slice(0, index)
                      .reduce(
                        (acc, col) =>
                          acc + (dynamicWidth[col.codCampo] ?? col.width),
                        0,
                      );

                    const style: React.CSSProperties = {
                      zIndex: isFixed ? qtdColumns - index + 1 : undefined,
                      position: isFixed ? 'sticky' : undefined,
                      left: isFixed ? `${leftPosition}px` : undefined,
                      backgroundColor:
                        hoveredRowIndex === idx ? colorHover : colorLine,
                    };

                    return (
                      <Td
                        key={column.codCampo}
                        headers={column.headerName}
                        onClick={() => onRowClick({ row })}
                        width={calculatedWidth}
                        color={colorText}
                        isFixed={isFixed}
                        style={style}
                      >
                        {formatValue(row[column.field], column)}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}

            {showAgregadores && (
              <TrAgregadores count={items} isFixed={isFixedList}>
                {columns.map((col, indx) => {
                  const agregadorInfo = agregadoresPorCampo.get(col.codCampo);
                  const field = agregadorInfo?.field;
                  const total = field ? totals[field] : undefined;
                  const label = agregadorLabel(
                    Number(agregadorInfo?.tipo_agregador_tot),
                  );

                  const qtdColumns =
                    columns.length === qtdColunasFixadas
                      ? 0
                      : qtdColunasFixadas;

                  const isFixed = indx < qtdColumns;

                  const leftPosition = columns
                    .slice(0, indx)
                    .reduce(
                      (acc, column) =>
                        acc + (dynamicWidth[col.codCampo] ?? column.width),
                      0,
                    );

                  const style: React.CSSProperties = {
                    zIndex: isFixed ? qtdColumns - indx + 1 : undefined,
                    position: isFixed ? 'sticky' : undefined,
                    left: isFixed ? `${leftPosition}px` : undefined,
                    backgroundColor: isFixed
                      ? '#e5e5e5'
                      : getBackgroundColor(agregadoresPorCampo, col.codCampo),
                  };

                  return (
                    <Agregadores
                      key={col.codCampo}
                      agregadoresPorCampo={agregadoresPorCampo}
                      col={col}
                      total={total}
                      label={label}
                      isFixed={isFixed}
                      styleFixed={style}
                    />
                  );
                })}
              </TrAgregadores>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationComponent />
    </div>
  );
};
