/* eslint-disable no-console */
import React from 'react';

import { DataTableProvider } from './context/DataTableContext';
import { DataTableContent } from './DataTableContent';
import { DataTableProps } from './types';

export const DataTable: React.FC<DataTableProps> = ({
  columns,
  rows,
  loading,
  components,
  totals,
  onRowClick,
  handleSort,
  sortCampos,
}) => {
  return (
    <DataTableProvider>
      <DataTableContent
        columns={columns}
        rows={rows}
        loading={loading}
        components={components}
        totals={totals}
        onRowClick={onRowClick}
        handleSort={handleSort}
        sortCampos={sortCampos}
      />
    </DataTableProvider>
  );
};
