import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type DynamicWidth = {
  [key: number]: number | null;
};

interface DataTableContextData {
  dynamicWidth: DynamicWidth;
  onDynamicWidth: (key: number, width: number | null) => void;
}

interface DataTableProps {
  children: ReactNode;
}

export const DataTableContext = createContext({} as DataTableContextData);

export const DataTableProvider: React.FC<DataTableProps> = ({ children }) => {
  const [dynamicWidth, setDynamicWidth] = useState<DynamicWidth>({});

  const onDynamicWidth = useCallback((key: number, width: number | null) => {
    setDynamicWidth((prevDynamicWidth) => ({
      ...prevDynamicWidth,
      [key]: width,
    }));
  }, []);

  const contextValue = useMemo(() => {
    return { dynamicWidth, onDynamicWidth };
  }, [dynamicWidth, onDynamicWidth]);

  return (
    <DataTableContext.Provider value={contextValue}>
      {children}
    </DataTableContext.Provider>
  );
};

export const useDataTable = (): DataTableContextData =>
  useContext(DataTableContext);
