import { HeadersProps } from '../types';

export const formatValue = (
  value: string | number,
  column?: HeadersProps,
): string => {
  if (value === null || value === undefined) return '';

  if (typeof value === 'string' || typeof value === 'boolean')
    return String(value);

  const NumFractionDecimal = 2;

  let numFractionDigits = Number.isInteger(value) ? 0 : NumFractionDecimal;

  // Formatação quando for tipo decimal - 4
  if (column) {
    if (column.cod_tipo_dados === 4) {
      if (column.tipo_expressao === 1 && column.listagem_info) {
        const listagem_info = JSON.parse(column.listagem_info);

        const listagem_info_valor = listagem_info[0].valor;

        if (listagem_info_valor) {
          const split_info = listagem_info_valor.split(',');

          if (split_info.length > 1) {
            numFractionDigits = split_info[1].length;
          }
        }
      } else {
        numFractionDigits = 2;
      }
    }
  }

  return !Number.isNaN(value)
    ? new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: numFractionDigits,
        maximumFractionDigits: numFractionDigits,
      }).format(value)
    : String(value);
};
