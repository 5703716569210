export const agregadorLabel = (tipoAgregador: number): string => {
  switch (tipoAgregador) {
    case 0:
      return 'Contagem';
    case 1:
      return 'Cont. Distinta';
    case 2:
      return 'Soma';
    case 3:
      return 'Máximo';
    case 4:
      return 'Mínimo';
    case 5:
      return 'Média';
    default:
      return 'Nenhum';
  }
};
