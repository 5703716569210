import styled from 'styled-components';

interface StyledProps {
  width?: number;
  color?: string;
  bgColor?: string;
  bgHover?: string;
  count?: number;
  hasAgregador?: boolean;
}

export const TableContainer = styled.div<StyledProps>`
  width: 100%;
  height: ${(props) => (props?.count === 0 ? '23.75rem' : 'auto')};
  min-height: 200px;
  overflow: auto;
  position: relative;

  ::-webkit-scrollbar {
    width: 0.625rem;
    height: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8850bf;
    transition: background-color 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #7544a4;
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
`;

export const Table = styled.table`
  width: 100%;
  min-width: 100%;
`;

export const TableHeader = styled.thead<{ qtdFixed: number }>`
  display: flex;
  align-items: center;
  background-color: #8850bf;
  color: white;
  height: 3.5rem;
  position: sticky;
  z-index: ${(props) => props.qtdFixed + 2};
`;

export const TrHeader = styled.tr`
  padding: 0 !important;
  display: inline-table;
`;

export const Th = styled.th<StyledProps & { isFixed?: boolean }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  font-size: 0.875rem;
  letter-spacing: 0.01071em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  &:first-child {
    padding-left: 1.2rem;
  }

  div {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 100%;

    span {
      display: flex;
      align-items: center;
      gap: 0.4063rem;

      svg {
        opacity: 75%;
      }

      svg.TbArrowsUpDown {
        opacity: 0;
      }
    }
  }

  &:hover {
    span {
      svg {
        opacity: 75%;

        &:hover {
          height: '2.1875rem';
          width: '2.1875rem';
          background-color: rgba(0, 0, 0, 0.33);
          border-radius: 624.9375rem;
        }
      }

      svg.TbArrowsUpDown {
        opacity: 70%;
      }
    }
  }

  /* Estilo para colunas fixadas */
  ${(props) =>
    props.isFixed &&
    `
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: '#8850bf';
    overflow: hidden;
  `}
`;

export const Tbody = styled.tbody<StyledProps>`
  height: ${(props) => (props.count && props.count <= 4 ? '19.6rem' : 'auto')};
  position: relative;

  p.noRecordsFound {
    position: fixed;
    text-align: center;
    width: 100%;
    height: 19.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
`;

export const Tr = styled.tr<StyledProps>`
  display: flex !important;
  padding: 0 !important;
  border-bottom: 0.0437rem solid #e0e0e0;

  background-color: ${(props) => props.bgColor};

  &&& {
    padding: 0 !important;
  }

  &:hover {
    background-color: ${(props) => props.bgHover};
  }
`;

export const Td = styled.td<StyledProps & { isFixed?: boolean }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  padding: 1.3rem 0.01rem;

  &:first-child {
    padding-left: 1.2rem !important;
  }

  &:not(:first-child) {
    padding-left: 0 !important;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.01071em;
  color: ${(props) => props.color};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  /* Estilo para colunas fixadas */
  ${(props) =>
    props.isFixed &&
    `
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: ${props.bgColor};
    overflow: hidden;
  `}
`;

export const TrAgregadores = styled.tr<StyledProps & { isFixed?: boolean[] }>`
  position: ${(props) =>
    props.count && props.count <= 4 ? 'absolute' : 'sticky'};

  bottom: 0;
  background-color: ${(props) =>
    props.isFixed?.some((isFixed) => isFixed) ? '#e5e5e5' : '#f1f1f1'};

  display: flex !important;
  padding: 0 !important;

  &&& {
    padding: 0 !important;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const TdAgregadores = styled.td<StyledProps & { isFixed?: boolean }>`
  padding: 0.9375rem 0 !important;

  display: flex !important;

  &:first-child {
    padding-left: 0.5313rem !important;
  }

  &:not(:first-child) {
    padding-left: 0 !important;
  }

  font-weight: 700;
  background-color: ${(props) => props.bgColor};
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  text-transform: uppercase;
  font-size: 0.8125rem;

  /* Estilo para colunas fixadas */
  ${(props) =>
    props.isFixed &&
    `
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: ${props.bgColor};
    overflow: hidden;
  `}
`;
