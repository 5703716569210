/* eslint-disable no-shadow */
import { ComponentType } from 'react';

import { SortCamposProps } from '../Search/types';

export enum AgregadorTipo {
  COUNT = 0,
  COUNT_DISTINCT = 1,
  SUM = 2,
  MAX = 3,
  MIN = 4,
  AVG = 5,
  NENHUM = -1,
}

export type HeadersProps = {
  codCampo: number;
  disableReorder: boolean;
  field: string;
  headerName: string;
  nome_bd: string;
  order: number;
  sortingOrder: string[];
  visibility: boolean;
  width: number;
  tipo_agregador_tot: number;
  cod_tipo_dados: number;
  tipo_expressao: number | null;
  listagem_info: string | null;
};

type DataTableComponents = {
  LoadingOverlay?: ComponentType<any>;
  Pagination?: ComponentType<any>;
};

export type DataTableProps = {
  columns: HeadersProps[];
  rows: readonly any[];
  loading: boolean;
  components?: DataTableComponents;
  totals: any;
  onRowClick: (param: any) => void;
  handleSort: (field: string) => void;
  sortCampos: SortCamposProps[];
};
